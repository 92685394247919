import { PublicKey } from '@solana/web3.js'
import ActionWrapper from '../interfaces/Action'
import NftMetadata from '../interfaces/NftMetadata'
import { isOverlord } from '../utils/checkNft'
import parseNftName from '../utils/parseNftName'
import Img from './Img'

interface Props {
    nfts: NftMetadata[],
    actions: ActionWrapper[],
    name: string,
    additionalMints: PublicKey[]
}

const NftList = (props: Props) => {
    return (
        props.nfts.length > 0 ? <div className="nftList">{props.nfts.map(nft =>
            <div key={nft.mint.toBase58()} className={isOverlord(nft) ? "nft overlord" : "nft"}>
                <Img className="image" src={nft.image} />
                <div className="name">
                    <h4 className='collection'>{parseNftName(nft.name).collection}</h4>
                    <h4 className='index'>{parseNftName(nft.name).index}</h4>
                </div>
                {props.actions.map(action =>
                    <button onClick={() => action.action([nft.mint, ...props.additionalMints])} className="actionButton" key={action.name}>{action.name}</button>
                )}
            </div>
        )}     </div> : <h3>You don't have any {props.name}</h3>
    )
}

export default NftList