export type Metalairs = {
    "version": "0.1.0",
    "name": "metalairs",
    "instructions": [
      {
        "name": "initProgramAccounts",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vault",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vaultEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollector",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollectorEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "startBuild",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollector",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollectorEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "cancelBuild",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "addBuilder",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "buildDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollector",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollectorEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "withdrawBuilder",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "buildDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "buildAccountBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "mintLair",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "updateAuthority",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairMasterEdition",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "initializerLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenMetadataProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "metadataUri",
            "type": "string"
          },
          {
            "name": "newTrait",
            "type": {
              "array": [
                "u8",
                2
              ]
            }
          }
        ]
      },
      {
        "name": "lockLair",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "playAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "playAccountLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "unlockLair",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "playAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "playAccountLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "playAccountBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "lockDrago",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccountDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "unlockDrago",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccountDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "lairPlayAccountBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "startAction",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "action",
            "type": "u8"
          }
        ]
      },
      {
        "name": "claimAction",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vault",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vaultEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "vaultBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "cancelAction",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "recoverBuildAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "isActive",
            "type": "bool"
          },
          {
            "name": "numBuilders",
            "type": "u16"
          },
          {
            "name": "numFrozen",
            "type": "u16"
          },
          {
            "name": "savedPoints",
            "type": "u64"
          },
          {
            "name": "lastClaim",
            "type": "i64"
          },
          {
            "name": "lastBuild",
            "type": "i64"
          }
        ]
      },
      {
        "name": "recoverDragoAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "lastBuild",
            "type": "i64"
          }
        ]
      },
      {
        "name": "recoverLairAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "tier",
            "type": "u8"
          },
          {
            "name": "traits",
            "type": {
              "array": [
                "u8",
                7
              ]
            }
          }
        ]
      },
      {
        "name": "recoverStatsAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "lairsBuilding",
            "type": "u64"
          },
          {
            "name": "lairsBuilt",
            "type": "u64"
          }
        ]
      }
    ],
    "accounts": [
      {
        "name": "statsAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "lairsBuilding",
              "type": "u64"
            },
            {
              "name": "lairsBuilt",
              "type": "u64"
            }
          ]
        }
      },
      {
        "name": "buildAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "isActive",
              "type": "bool"
            },
            {
              "name": "numBuilders",
              "type": "u16"
            },
            {
              "name": "numFrozen",
              "type": "u16"
            },
            {
              "name": "savedPoints",
              "type": "u64"
            },
            {
              "name": "lastClaim",
              "type": "i64"
            },
            {
              "name": "lastBuild",
              "type": "i64"
            }
          ]
        }
      },
      {
        "name": "dragoAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "lastBuild",
              "type": "i64"
            }
          ]
        }
      },
      {
        "name": "dragoPlayAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "isLocked",
              "type": "bool"
            },
            {
              "name": "action",
              "type": "u8"
            },
            {
              "name": "actionStartedAt",
              "type": "i64"
            }
          ]
        }
      },
      {
        "name": "lairAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "tier",
              "type": "u8"
            },
            {
              "name": "traits",
              "type": {
                "array": [
                  "u8",
                  7
                ]
              }
            }
          ]
        }
      },
      {
        "name": "lairPlayAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "isLocked",
              "type": "bool"
            },
            {
              "name": "lockedDragos",
              "type": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          ]
        }
      }
    ],
    "types": [
      {
        "name": "BuildErrors",
        "type": {
          "kind": "enum",
          "variants": [
            {
              "name": "LairsMintedOut"
            },
            {
              "name": "BuildNotActive"
            },
            {
              "name": "BuildNotFinished"
            },
            {
              "name": "BuildHasAlreadyStarted"
            },
            {
              "name": "FrozenDragosAreNotWithdrawn"
            },
            {
              "name": "DragoIsFrozen"
            },
            {
              "name": "LairIsNotLocked"
            },
            {
              "name": "LairIsLocked"
            },
            {
              "name": "NoLockedDragos"
            }
          ]
        }
      },
      {
        "name": "PlayErrors",
        "type": {
          "kind": "enum",
          "variants": [
            {
              "name": "LairIsAlreadyLocked"
            },
            {
              "name": "LairIsNotLocked"
            },
            {
              "name": "DragoIsNotLocked"
            },
            {
              "name": "DragosAreNotWithdrawn"
            },
            {
              "name": "DragoTypeWithdrawn"
            },
            {
              "name": "LairDragosLimitExceeded"
            },
            {
              "name": "ActionIsActive"
            },
            {
              "name": "ActionIsNotActive"
            },
            {
              "name": "WrongAction"
            }
          ]
        }
      }
    ],
    "errors": [
      {
        "code": 6000,
        "name": "WrongMetadataAccount",
        "msg": "Wrong metadata account"
      },
      {
        "code": 6001,
        "name": "WrongCollection",
        "msg": "Wrong collection"
      },
      {
        "code": 6002,
        "name": "NoAllowedCreator",
        "msg": "No allowed creator"
      },
      {
        "code": 6003,
        "name": "WrongTraitData",
        "msg": "Wrong trait data"
      }
    ]
  };
  
  export const IDL: Metalairs = {
    "version": "0.1.0",
    "name": "metalairs",
    "instructions": [
      {
        "name": "initProgramAccounts",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vault",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vaultEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollector",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollectorEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "startBuild",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollector",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollectorEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "cancelBuild",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "addBuilder",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "buildDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollector",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "feeCollectorEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "withdrawBuilder",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "buildDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "buildAccountBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "mintLair",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "updateAuthority",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairMasterEdition",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "initializerLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenMetadataProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "metadataUri",
            "type": "string"
          },
          {
            "name": "newTrait",
            "type": {
              "array": [
                "u8",
                2
              ]
            }
          }
        ]
      },
      {
        "name": "lockLair",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "playAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "playAccountLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "unlockLair",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "playAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "playAccountLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerLairAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "playAccountBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "lockDrago",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccountDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "unlockDrago",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairPlayAccountDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerDragoAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "lairPlayAccountBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "startAction",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "action",
            "type": "u8"
          }
        ]
      },
      {
        "name": "claimAction",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMetadata",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "emberMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "initializerEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vault",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "vaultEmberAta",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "associatedTokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "clock",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "vaultBump",
            "type": "u8"
          }
        ]
      },
      {
        "name": "cancelAction",
        "accounts": [
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoPlayAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": []
      },
      {
        "name": "recoverBuildAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "initializer",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "buildAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "isActive",
            "type": "bool"
          },
          {
            "name": "numBuilders",
            "type": "u16"
          },
          {
            "name": "numFrozen",
            "type": "u16"
          },
          {
            "name": "savedPoints",
            "type": "u64"
          },
          {
            "name": "lastClaim",
            "type": "i64"
          },
          {
            "name": "lastBuild",
            "type": "i64"
          }
        ]
      },
      {
        "name": "recoverDragoAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "dragoAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "dragoMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "lastBuild",
            "type": "i64"
          }
        ]
      },
      {
        "name": "recoverLairAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "lairAccount",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "lairMint",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "tier",
            "type": "u8"
          },
          {
            "name": "traits",
            "type": {
              "array": [
                "u8",
                7
              ]
            }
          }
        ]
      },
      {
        "name": "recoverStatsAccount",
        "accounts": [
          {
            "name": "recoverer",
            "isMut": true,
            "isSigner": true
          },
          {
            "name": "stats",
            "isMut": true,
            "isSigner": false
          },
          {
            "name": "tokenProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "systemProgram",
            "isMut": false,
            "isSigner": false
          },
          {
            "name": "rent",
            "isMut": false,
            "isSigner": false
          }
        ],
        "args": [
          {
            "name": "lairsBuilding",
            "type": "u64"
          },
          {
            "name": "lairsBuilt",
            "type": "u64"
          }
        ]
      }
    ],
    "accounts": [
      {
        "name": "statsAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "lairsBuilding",
              "type": "u64"
            },
            {
              "name": "lairsBuilt",
              "type": "u64"
            }
          ]
        }
      },
      {
        "name": "buildAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "isActive",
              "type": "bool"
            },
            {
              "name": "numBuilders",
              "type": "u16"
            },
            {
              "name": "numFrozen",
              "type": "u16"
            },
            {
              "name": "savedPoints",
              "type": "u64"
            },
            {
              "name": "lastClaim",
              "type": "i64"
            },
            {
              "name": "lastBuild",
              "type": "i64"
            }
          ]
        }
      },
      {
        "name": "dragoAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "lastBuild",
              "type": "i64"
            }
          ]
        }
      },
      {
        "name": "dragoPlayAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "isLocked",
              "type": "bool"
            },
            {
              "name": "action",
              "type": "u8"
            },
            {
              "name": "actionStartedAt",
              "type": "i64"
            }
          ]
        }
      },
      {
        "name": "lairAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "tier",
              "type": "u8"
            },
            {
              "name": "traits",
              "type": {
                "array": [
                  "u8",
                  7
                ]
              }
            }
          ]
        }
      },
      {
        "name": "lairPlayAccount",
        "type": {
          "kind": "struct",
          "fields": [
            {
              "name": "isLocked",
              "type": "bool"
            },
            {
              "name": "lockedDragos",
              "type": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          ]
        }
      }
    ],
    "types": [
      {
        "name": "BuildErrors",
        "type": {
          "kind": "enum",
          "variants": [
            {
              "name": "LairsMintedOut"
            },
            {
              "name": "BuildNotActive"
            },
            {
              "name": "BuildNotFinished"
            },
            {
              "name": "BuildHasAlreadyStarted"
            },
            {
              "name": "FrozenDragosAreNotWithdrawn"
            },
            {
              "name": "DragoIsFrozen"
            },
            {
              "name": "LairIsNotLocked"
            },
            {
              "name": "LairIsLocked"
            },
            {
              "name": "NoLockedDragos"
            }
          ]
        }
      },
      {
        "name": "PlayErrors",
        "type": {
          "kind": "enum",
          "variants": [
            {
              "name": "LairIsAlreadyLocked"
            },
            {
              "name": "LairIsNotLocked"
            },
            {
              "name": "DragoIsNotLocked"
            },
            {
              "name": "DragosAreNotWithdrawn"
            },
            {
              "name": "DragoTypeWithdrawn"
            },
            {
              "name": "LairDragosLimitExceeded"
            },
            {
              "name": "ActionIsActive"
            },
            {
              "name": "ActionIsNotActive"
            },
            {
              "name": "WrongAction"
            }
          ]
        }
      }
    ],
    "errors": [
      {
        "code": 6000,
        "name": "WrongMetadataAccount",
        "msg": "Wrong metadata account"
      },
      {
        "code": 6001,
        "name": "WrongCollection",
        "msg": "Wrong collection"
      },
      {
        "code": 6002,
        "name": "NoAllowedCreator",
        "msg": "No allowed creator"
      },
      {
        "code": 6003,
        "name": "WrongTraitData",
        "msg": "Wrong trait data"
      }
    ]
  };
  