import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { FC, useMemo } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter, Route, Routes, Navigate, Router, } from "react-router-dom";
import Build from './pages/Build';
import DragosExplorerViewer from './pages/DragosExplorerViewer';

import './css/App.css'
import './css/Nft.css'
import '@solana/wallet-adapter-react-ui/styles.css'
import Explorer from './pages/Explorer';
import Home from './pages/Home';
import Play from './pages/Play';
import { NETWORK, RPC_ENDPOINT } from './vars';

const App: FC = () => {

    const connection = new Connection(RPC_ENDPOINT)

    const wallets = [
        new PhantomWalletAdapter(),
        new GlowWalletAdapter(),
        new SlopeWalletAdapter(),
        new SolflareWalletAdapter({ network: NETWORK as WalletAdapterNetwork }),
        new TorusWalletAdapter(),
    ]

    return (
        <ConnectionProvider endpoint={RPC_ENDPOINT}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <div className="App">
                        <BrowserRouter>
                            <Navbar />
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/build" element={<Build connection={connection} />} />
                                <Route path="/play" element={<Play connection={connection} />} />
                                <Route path="/explorer" element={<Explorer />} />
                                <Route path="/explorer/dragos/:publicKey" element={<DragosExplorerViewer />} />
                            </Routes>
                        </BrowserRouter>
                    </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider >
    );
};

export default App;