import lockLair from "./lockLair"
import unlockLair from "./unlockLair"
import lockDrago from "./lockDrago"
import unlockDrago from "./unlockDrago"
import startAction from "./startAction"
import cancelAction from "./cancelAction"
import claimAction from "./claimAction"
import startWork from "./startWork"

export default {
    lockLair,
    unlockLair,
    lockDrago,
    unlockDrago,
    startAction,
    cancelAction,
    claimAction,
    startWork
}