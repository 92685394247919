import { PublicKey } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import NftMetadata from '../interfaces/NftMetadata'
import parseNftName from '../utils/parseNftName'
import Countdown from 'react-countdown'
import '../css/LockedLair.css'
import Action from '../interfaces/Action'
import { useEffect, useState } from 'react'
import NftList from './NftList'
import getDragoGen from '../utils/getDragoGen'
import { AnchorWallet, useAnchorWallet } from '@solana/wallet-adapter-react'
import { IDL, Metalairs } from '../idl'
import { DRAGOS_CREATORS, DRAGOS_PREFIXES, BASE_RATE, NEEDED_ACTION_POINTS, PROGRAM_ID } from '../vars'
import checkNft, { isOverlord } from '../utils/checkNft'
import getOwnerNfts from '../utils/getOwnerNfts'
import getExternalMetadata from '../utils/getExternalMetadata'
import Config from '../interfaces/Config'
import getEarnedActionPoints from '../utils/getEarnedActionPoints'
import Img from './Img'

interface Props {
    nft: NftMetadata,
    lair: NftMetadata,
    actions: Action[],
    config: Config
}

const LockedDrago = (props: Props) => {
    const [action, setAction] = useState(0)
    const [dragoAccountData, setDragoAccountData] = useState<any>([])
    const [earnedPoints, setEarnedPoints] = useState(0)
    const lockedDragoTypes = ['Start Work', 'Unassign']
    const actionDragoTypes = ['Cancel Work']
    const doneActionDragoTypes = ['Claim Action']

    const getDragoData = async (program: anchor.Program<Metalairs>, wallet: AnchorWallet) => {
        const [dragoPlayAccount, dragoPlayAccountBump] = await anchor.web3.PublicKey.findProgramAddress([props.nft.mint.toBuffer(), wallet.publicKey.toBuffer()], program.programId)
        const dragoPlayAccountData = await program.account.dragoPlayAccount.fetch(dragoPlayAccount)
        setDragoAccountData(dragoPlayAccountData)

        const earnedPoints = getEarnedActionPoints(props.nft, dragoPlayAccountData.actionStartedAt.toNumber())
        setEarnedPoints(earnedPoints)
    }

    useEffect(() => {
        getDragoData(props.config.program, props.config.wallet)
    }, [])
    return (
        <>
            {!dragoAccountData.action ? <div key={props.nft.mint.toBase58()} className={isOverlord(props.nft) ? "nft overlord" : "nft"} >
                <Img className="image" src={props.nft.image} />
                <div className="name">
                    <h4 className='collection'>{parseNftName(props.nft.name).collection}</h4>
                    <h4 className='index'>{parseNftName(props.nft.name).index}</h4>
                </div>
                {props.actions.filter(action => lockedDragoTypes.includes(action.name)).map(action =>
                    <button onClick={() => action.action([props.nft.mint, props.lair.mint])} className="actionButton" key={action.name}>{action.name}</button>
                )}
            </div> : <div key={props.nft.mint.toBase58()} className={isOverlord(props.nft) ? "nft overlord" : "nft"} >
                <Img className="image" src={props.nft.image} />
                <div className="name">
                    <h4 className='collection'>{parseNftName(props.nft.name).collection}</h4>
                    <h4 className='index'>{parseNftName(props.nft.name).index}</h4>
                </div>
                {earnedPoints >= NEEDED_ACTION_POINTS[dragoAccountData.action as keyof typeof NEEDED_ACTION_POINTS]
                    ? <div className="action">
                        {props.actions.filter(action => doneActionDragoTypes.includes(action.name)).map(action =>
                            <button onClick={() => action.action([props.nft.mint, props.lair.mint])} className="actionButton" key={action.name}>{action.name}</button>
                        )}
                    </div>
                    : <div className="action">
                        <h4 className='eta'><Countdown className='eta' date={Date.now() + (NEEDED_ACTION_POINTS[dragoAccountData.action as keyof typeof NEEDED_ACTION_POINTS] - earnedPoints) / BASE_RATE * 1000} /></h4>
                        {props.actions.filter(action => actionDragoTypes.includes(action.name)).map(action =>
                            <button onClick={() => action.action([props.nft.mint, props.lair.mint])} className="actionButton" key={action.name}>{action.name}</button>
                        )}
                    </div>
                }
            </div>}
        </>

    )
}

export default LockedDrago