import { PublicKey } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import NftMetadata from '../interfaces/NftMetadata'
import parseNftName from '../utils/parseNftName'

import '../css/LockedLair.css'
import ActionWrapper from '../interfaces/Action'
import { useEffect, useState } from 'react'
import NftList from './NftList'
import getDragoGen from '../utils/getDragoGen'
import { AnchorWallet, useAnchorWallet } from '@solana/wallet-adapter-react'
import { IDL, Metalairs } from '../idl'
import { DRAGOS_CREATORS, DRAGOS_PREFIXES, PROGRAM_ID } from '../vars'
import checkNft from '../utils/checkNft'
import getOwnerNfts from '../utils/getOwnerNfts'
import getExternalMetadata from '../utils/getExternalMetadata'
import Config from '../interfaces/Config'
import LockedDrago from './LockedDrago'
import Img from './Img'

interface Props {
    lair: NftMetadata,
    walletDragos: NftMetadata[],
    actions: ActionWrapper[],
    config: Config
}

const LockedLair = (props: Props) => {
    const [selectingDrago, setSelectingDrago] = useState(0)
    const [lockedDragos, setLockedDragos] = useState<NftMetadata[]>([])
    const lairActionTypes = ['Uninstall']
    const walletDragoTypes = ['Assign']

    const loadLockedDragos = async (provider: anchor.Provider, program: anchor.Program<Metalairs>, wallet: AnchorWallet) => {
        const [lairPlayAccount, lairPlayAccountBump] = await anchor.web3.PublicKey.findProgramAddress([props.lair.mint.toBuffer(), wallet.publicKey.toBuffer()], program.programId)

        const nfts = await getOwnerNfts(provider.connection, lairPlayAccount)
        nfts.forEach(async nft => {
            if (!(checkNft(nft, DRAGOS_PREFIXES, DRAGOS_CREATORS))) {
                return
            }
            const nftExternalMetadata = await getExternalMetadata(nft)
            if (!nftExternalMetadata) return

            setLockedDragos(nfts => [...nfts, nftExternalMetadata])
        })
    }

    useEffect(() => {
        loadLockedDragos(props.config.provider, props.config.program, props.config.wallet)
    }, [])

    return (
        <div key={props.lair.mint.toBase58()} className="nft lockedLair">
            <div className="lair">
                <Img className="image lairImage" src={props.lair.image} />
                {lockedDragos.length == 0 ?
                    props.actions.filter(action => lairActionTypes.includes(action.name)).map(action =>
                        <button onClick={() => action.action([props.lair.mint])} className="actionButton lairActionButton" key={action.name} >{action.name}</button>
                    )
                    : null}
            </div>
            <div className="dragos">
                <div className='lockedDragos'>
                    {lockedDragos.filter(drago => getDragoGen(drago) === 1).map(nft =>
                        <LockedDrago nft={nft} lair={props.lair} config={props.config} actions={props.actions} key={nft.mint.toBase58()} />
                    )}
                    {new Array(1 - lockedDragos.filter(drago => getDragoGen(drago) === 1).length).fill(1).map((v, i) =>
                        <div onClick={() => setSelectingDrago(1)} className='nft lockDrago gen1' key={i}>
                            <h4>Add Gen1</h4>
                        </div>
                    )}
                    {lockedDragos.filter(drago => getDragoGen(drago) === 2).map(nft =>
                        <LockedDrago nft={nft} lair={props.lair} config={props.config} actions={props.actions} key={nft.mint.toBase58()} />
                    )}
                    {new Array(3 - lockedDragos.filter(drago => getDragoGen(drago) === 2).length).fill(1).map((v, i) =>
                        <div onClick={() => setSelectingDrago(2)} className='nft lockDrago gen2' key={i}>
                            <h4>Add Gen2</h4>
                        </div>
                    )}
                </div>
                {
                    selectingDrago ?
                        <NftList name='Dragos' nfts={props.walletDragos.filter(drago => getDragoGen(drago) === selectingDrago)
                        } additionalMints={[props.lair.mint]} actions={props.actions.filter(action => walletDragoTypes.includes(action.name))} />
                        : null}
            </div>
        </div>
    )
}

export default LockedLair