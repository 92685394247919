import { useState } from 'react';
import '../css/Build.css'
import '../css/Explorer.css'

import InfoModal from '../components/InfoModal';
import { useNavigate } from "react-router-dom";
import { PublicKey } from '@solana/web3.js';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

const Explorer = () => {

    const navigate = useNavigate()
    const wallet = useAnchorWallet()

    const [modalNotification, setModalNotification] = useState<{ status: string, text: string, action?: any }>({ status: '', text: '' })
    const [dragoPublicKey, setDragoPublicKey] = useState<string>("")

    const find = () => {
        try {
            const pk = new PublicKey(dragoPublicKey)
            navigate(`dragos/${dragoPublicKey}`)
        } catch (e) {
            setModalNotification({ status: 'error', text: "Incorrect PublicKey" })
        }
    }
    return (
        <div className="Page Explorer">
            {wallet ? <><h3><span className='highlight'>F</span>ind info about MetaDrago</h3>
                <h4>Use this tool to check if a GEN2 drago is on its cooldown period after a successful build</h4>
                <div className='explore'>
                    <input className='input' placeholder='Drago Mint Address' value={dragoPublicKey} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setDragoPublicKey(e.target.value)} />
                    <button className='actionButton' onClick={find}>Find!</button>
                </div>
            </> : <h1 className='connectWalletAlert'>Connect your wallet to access this page</h1>}
            {modalNotification.status ? <InfoModal modalNotification={modalNotification} setModalNotification={setModalNotification} /> : null}
        </div >
    )
}

export default Explorer