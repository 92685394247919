import * as anchor from "@project-serum/anchor"
import { PublicKey } from "@solana/web3.js";
import { Metalairs } from "../idl";

export default async (dragoMint: PublicKey, program: anchor.Program<Metalairs>) => {
    const [dragoAccount, dragoAccountBump] = await anchor.web3.PublicKey.findProgramAddress([dragoMint.toBuffer()], program.programId)
    try {
        return await program.account.dragoAccount.fetch(dragoAccount)
    } catch (e) {
        return
    }
}