import '../css/Build.css'

import { useState, useEffect } from "react";
import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react"
import { IDL, Metalairs } from "../idl";
import InfoModal from "../components/InfoModal";
import actions from "../actions";
import NftMetadata from "../interfaces/NftMetadata";
import getOwnerNfts from "../utils/getOwnerNfts";
import { BUILD_DRAGOS_PREFIXES, DRAGOS_CREATORS, LAIRS_CREATORS, NEEDED_BUILD_POINTS, PROGRAM_ID, RPC_ENDPOINT } from "../vars";
import getEarnedBuildPoints from '../utils/getEarnedBuildPoints';
import BuildAccount from '../components/BuildAccount';
import WalletDragos from '../components/WalletDragos';
import getExternalMetadata from '../utils/getExternalMetadata';
import getDragoAccountData from '../utils/getDragoAccountData';
import BuildStats from '../components/BuildStats';
import getErrorText from '../utils/getErrorText';
import PageProps from '../interfaces/PageProps';
import NftList from '../components/NftList';
import checkNft from '../utils/checkNft';
import { Action } from '../interfaces/Action';
import sendAndConfirmTx from '../utils/sendAndConfirmTx';
import sortNft from '../utils/sortNft';

const Build = (props: PageProps) => {
    const [provider, setProvider] = useState<anchor.AnchorProvider>()
    const [program, setProgram] = useState<anchor.Program<Metalairs>>()
    const [loadingStates, setLoadingStates] = useState({
        walletDragos: false,
        workingDragos: false,
        walletLairs: false,
        buildAccount: false
    })
    const [modalNotification, setModalNotification] = useState<{ status: string, text: string, action?: any }>({ status: '', text: '' })
    const [buildPoints, setBuildPoints] = useState<number>(0)
    const [buildAccount, setBuildAccount] = useState<any>()
    const [workingDragos, setWorkingDragos] = useState<Array<NftMetadata>>([])
    const [dragosData, setDragosData] = useState<Array<any>>([])
    const [walletDragos, setWalletDragos] = useState<Array<NftMetadata>>([])
    const [walletLairs, setWalletLairs] = useState<Array<NftMetadata>>([])

    const wallet = useAnchorWallet()

    const loadNfts = async (provider: anchor.Provider, program: anchor.Program<Metalairs>) => {
        if (!wallet) return

        setLoadingStates(loadingStates => ({ ...loadingStates, walletDragos: true, walletLairs: true }))

        const nfts = await getOwnerNfts(provider.connection, wallet.publicKey)
        nfts.forEach(async nft => {
            if (!(checkNft(nft, BUILD_DRAGOS_PREFIXES, DRAGOS_CREATORS) || checkNft(nft, ['MetaLair'], LAIRS_CREATORS))) {
                return
            }
            const nftExternalMetadata = await getExternalMetadata(nft)
            if (!nftExternalMetadata) return

            if (checkNft(nft, BUILD_DRAGOS_PREFIXES, DRAGOS_CREATORS)) {
                const dragoData = await getDragoAccountData(new PublicKey(nft.mint), program)
                setDragosData(dragosData => [...dragosData, dragoData])
                setWalletDragos(walletDragos => [...walletDragos, nftExternalMetadata])
            } else if (checkNft(nft, ['MetaLair'], LAIRS_CREATORS)) {
                setWalletLairs(walletLairs => [...walletLairs, nftExternalMetadata].sort(sortNft))
            }
        })

        setLoadingStates(loadingStates => ({ ...loadingStates, walletDragos: false, walletLairs: false }))
    }

    const loadBuild = async (provider: anchor.Provider, program: anchor.Program<Metalairs>) => {
        if (!wallet) return

        setLoadingStates(loadingStates => ({ ...loadingStates, buildAccount: true }))

        const [buildAccount, buildAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                Buffer.from("build_account"),
                wallet.publicKey.toBuffer(),
            ],
            program.programId
        )

        try {
            const buildAccountData = await program.account.buildAccount.fetch(buildAccount)

            if (buildAccountData.numBuilders > 0) {
                setLoadingStates(loadingStates => ({ ...loadingStates, buildAccount: true }))
                getOwnerNfts(provider.connection, buildAccount, (nft) => checkNft(nft, BUILD_DRAGOS_PREFIXES, DRAGOS_CREATORS)).then(nfts => {
                    nfts.forEach(async nft => {
                        const nftExternalMetadata = await getExternalMetadata(nft)
                        if (!nftExternalMetadata) return
                        setWorkingDragos(nfts => [...nfts, nftExternalMetadata].sort(sortNft))
                    })
                    setLoadingStates(loadingStates => ({ ...loadingStates, workingDragos: false }))
                })
            }

            if (buildAccountData.isActive) {
                const earnedPoints = getEarnedBuildPoints(buildAccountData.numBuilders, buildAccountData.lastClaim.toNumber())
                const buildPoints = Math.min(earnedPoints + buildAccountData.savedPoints.toNumber(), NEEDED_BUILD_POINTS)
                setBuildPoints(buildPoints)
            }

            setBuildAccount(buildAccountData)
        } catch { }
        setLoadingStates(loadingStates => ({ ...loadingStates, buildAccount: false }))
    }

    const reset = () => {
        setBuildAccount(null)
        setBuildPoints(0)
        setWorkingDragos([])
        setDragosData([])
        setWalletDragos([])
        setWalletLairs([])
    }

    const callAction = async (action: Action, mints: PublicKey[]) => {
        if (!program || !wallet || !provider) return

        try {
            const tx = await action(provider, program, mints)
            tx.recentBlockhash = !tx.recentBlockhash ? (await provider.connection.getLatestBlockhash()).blockhash : tx.recentBlockhash
            tx.feePayer = !tx.feePayer ? provider.wallet.publicKey : tx.feePayer
            const signedTx = await provider.wallet.signTransaction(tx)
            setModalNotification({ status: 'wait', text: 'Confirming Transaction...' })
            await sendAndConfirmTx(provider.connection, signedTx)
            setModalNotification({ status: 'success', text: 'Transaction Completed' })
            reset()
            loadNfts(provider, program)
            loadBuild(provider, program)
        } catch (e: any) {
            console.log(e)
            setModalNotification({ status: 'error', text: getErrorText(e) })
        }
    }


    useEffect(() => {
        if (!wallet) return
        if (provider?.wallet.publicKey.equals(wallet.publicKey)) return

        const newProvider = new anchor.AnchorProvider(props.connection, wallet, { commitment: 'confirmed' })
        const newProgram = new anchor.Program(IDL, PROGRAM_ID, newProvider)

        anchor.setProvider(newProvider)
        setProvider(newProvider)
        setProgram(newProgram)

        loadNfts(newProvider, newProgram)
        loadBuild(newProvider, newProgram)
    }, [wallet])

    return (
        <div className="Page">
            {wallet ?
                <>
                    {program ? <BuildStats program={program} /> : null}
                    <h2 className="sectionTitle"><span className='highlight'>B</span>uild:</h2>
                    {loadingStates.buildAccount ?
                        <h3>Loading Build Account...</h3> :
                        <BuildAccount buildAccount={buildAccount} buildPoints={buildPoints} loadingStates={loadingStates} walletLairs={walletLairs} mintLair={(mints: PublicKey[]) => callAction(actions.build.mintLair, mints)} cancelBuild={(mints: PublicKey[]) => callAction(actions.build.cancelBuild, mints)} startBuild={(mints: PublicKey[]) => callAction(actions.build.startBuild, mints)} setModalNotification={setModalNotification} />
                    }
                    <h2 className="sectionTitle"><span className='highlight'>W</span>allet GEN-2 Dragos:</h2>
                    {loadingStates.walletDragos ?
                        <h3>Loading Dragos...</h3> :
                        <WalletDragos walletDragos={walletDragos} dragosData={dragosData} addBuilder={(mints: PublicKey[]) => callAction(actions.build.addBuilder, mints)} />
                    }
                    <h2 className="sectionTitle"><span className='highlight'>W</span>orking GEN-2 Dragos:</h2>
                    {loadingStates.workingDragos ?
                        <h3>Loading Working Dragos...</h3> :
                        <>
                            {buildAccount && !buildAccount.isActive && buildAccount.numFrozen ? <h3 className="warning">You have to withdraw {buildAccount.numFrozen} Frozen Dragos</h3> : null}
                            <div className="workingDragos">
                                <NftList name='Working Dragos' nfts={workingDragos} additionalMints={[]} actions={buildAccount && !buildAccount.isActive ? [{ action: (mints: PublicKey[]) => callAction(actions.build.withdrawBuilder, mints), name: 'Withdraw' }] : []} />
                            </div>
                        </>
                    }
                </> : <h1 className='connectWalletAlert'>Connect your wallet to access this page</h1>
            }
            {modalNotification.status ? <InfoModal modalNotification={modalNotification} setModalNotification={setModalNotification} /> : null}
        </div >
    );
};

export default Build;
