import { PublicKey } from '@solana/web3.js'
import ActionWrapper from '../interfaces/Action'
import Config from '../interfaces/Config'

import LoadingStates from '../interfaces/LoadingStates'
import NftMetadata from '../interfaces/NftMetadata'
import parseNftName from '../utils/parseNftName'
import LockedLair from './LockedLair'

interface Props {
    lockedLairs: NftMetadata[],
    walletDragos: NftMetadata[],
    actions: ActionWrapper[],
    isLoading: boolean,
    config: Config
}

const LockedLairs = (props: Props) => {
    return (
        <div className="lairs lockedLairs">
            {props.isLoading ?
                <h3>Loading...</h3> :
                props.lockedLairs.length > 0 ? props.lockedLairs.map(lair =>
                    <LockedLair lair={lair} walletDragos={props.walletDragos} actions={props.actions} config={props.config} key={lair.mint.toBase58()} />
                ) : <h3>You don't have any Locked Lairs</h3>
            }
        </div>
    )
}

export default LockedLairs