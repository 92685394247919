import { useState } from 'react'
import '../css/InfoModal.css'
import LoadingStates from '../interfaces/LoadingStates'
import NftMetadata from '../interfaces/NftMetadata'
import Countdown from 'react-countdown'
import { NEEDED_BUILD_POINTS } from '../vars'
import { earnPerSecond } from '../utils/getEarnedBuildPoints'
import { ActionCaller } from '../interfaces/Action'

interface Props {
    buildAccount: any,
    buildPoints: number,
    loadingStates: LoadingStates,
    walletLairs: NftMetadata[],
    mintLair: ActionCaller,
    cancelBuild: ActionCaller,
    startBuild: ActionCaller,
    setModalNotification: React.Dispatch<React.SetStateAction<{
        status: string;
        text: string;
        action?: any;
    }>>
}

const BuildAccount = (props: Props) => {
    const [requests, setRequests] = useState({
        mintLair: false
    })

    const mintLair = async () => {
        setRequests(requests => ({ ...requests, mintLair: true }))
        await props.mintLair([])
        setRequests(requests => ({ ...requests, mintLair: false }))
    }

    const confirmCancelBuild = () => {
        props.setModalNotification({ status: 'confirm', text: 'Are you sure you want to cancel build? After cancellation fees will not be returned', action: () => props.cancelBuild([]) })
    }

    return (
        <div className="buildAccount">
            {props.buildAccount && props.buildAccount.isActive ?
                <div className="activeBuild">
                    <div className="lair">
                        <img className="image" src='/img/lairBuild.gif' />
                        <h4 className='progress'>Build: {Math.round(props.buildPoints / NEEDED_BUILD_POINTS * 100)}% / 100%</h4>
                        {props.buildAccount.numBuilders > 0 && props.buildPoints !== NEEDED_BUILD_POINTS ? <h4 className='eta'><Countdown className='eta' date={Date.now() + (NEEDED_BUILD_POINTS - props.buildPoints) / earnPerSecond(props.buildAccount.numBuilders) * 1000} /></h4> : null}
                        {props.buildPoints === NEEDED_BUILD_POINTS ?
                            requests.mintLair ? <button className="actionButton" disabled={true}>Generating...</button>
                                : <button className="actionButton" onClick={mintLair}>Mint Lair</button>
                            : <button className="actionButton" onClick={confirmCancelBuild}>Cancel Build</button>}
                    </div>
                </div> :
                <div className="notActiveBuild">
                    <h3>You don't have an active build</h3>
                    <div onClick={() => props.startBuild([])} className="nft buildLair"><h4>Start Building Lair</h4></div>
                </div>
            }
        </div>
    )
}

export default BuildAccount