import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: anchor.web3.PublicKey[]) => {
    const { wallet } = provider
    const [dragoMint] = mints

    const [buildAccount, buildAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("build_account"), wallet.publicKey.toBuffer()], program.programId)
    const [dragoAccount, dragoAccountBump] = await anchor.web3.PublicKey.findProgramAddress([dragoMint.toBuffer()], program.programId)

    const initializerDragoAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, dragoMint, wallet.publicKey)
    const buildDragoAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, dragoMint, buildAccount, true)

    return program.methods.withdrawBuilder(buildAccountBump).accounts({
        initializer: wallet.publicKey,
        buildAccount,
        dragoMint,
        dragoAccount,
        initializerDragoAta,
        buildDragoAta
    }).transaction()
}