import * as anchor from "@project-serum/anchor";
import axios from 'axios'
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { API_ENDPOINT } from "../../vars";
import sendAndConfirmTx from "../../utils/sendAndConfirmTx";
import { Metalairs } from "../../idl";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: anchor.web3.PublicKey[]) => {
    const { wallet } = provider
    const { serializedTx } = (await axios.post(`${API_ENDPOINT}/getLairInitialMintTx`, {
        initializer: wallet.publicKey
    })).data

    return anchor.web3.Transaction.from(Buffer.from(serializedTx, 'base64'))
}