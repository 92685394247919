import * as anchor from "@project-serum/anchor";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: anchor.web3.PublicKey[]) => {
    const { wallet } = provider
    const [buildAccount, buildAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("build_account"), wallet.publicKey.toBuffer()], program.programId)
    const [statsAccount, statsAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("stats")], program.programId)

    return program.methods.cancelBuild().accounts({
        initializer: wallet.publicKey,
        buildAccount,
        stats: statsAccount
    }).transaction()
}