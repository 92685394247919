import * as anchor from "@project-serum/anchor"
import * as metaplex from '@metaplex/js'
import { DRAGO_FREEZE_PERIOD } from "../vars"

export const checkDragoFreeze = (dragoAccount: any) => {
    if (!dragoAccount || !dragoAccount.lastBuild) return false
    return dragoAccount.lastBuild.toNumber() + DRAGO_FREEZE_PERIOD > Date.now() / 1000
}


export const checkDragoInBuild = (dragoMint: anchor.web3.PublicKey, connection: anchor.web3.Connection) => {
    return false
    // const { info } = await metaplex.programs.metadata.Metadata.findByMint(connection, dragoMint)
    // if (!info) return
    // console.log({ info })
    // console.log({ owner: info.owner.toBase58() })
}