import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getMetadata from "../../utils/getMetadata";
import { EMBER_MINT } from "../../vars";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: PublicKey[]) => {
    const { wallet } = provider
    const [lairMint] = mints
    const [playAccount, playAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("play_account"), wallet.publicKey.toBuffer()], program.programId)
    const [lairPlayAccount, lairPlayAccountBump] = await anchor.web3.PublicKey.findProgramAddress([lairMint.toBuffer(), wallet.publicKey.toBuffer()], program.programId)

    const initializerLairAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, lairMint, wallet.publicKey)
    const playAccountLairAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, lairMint, playAccount, true)

    return program.methods.unlockLair(playAccountBump).accounts({
        initializer: wallet.publicKey,
        playAccount,
        lairPlayAccount,
        lairMint,
        playAccountLairAta,
        initializerLairAta
    }).transaction()
}