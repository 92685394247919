import { PublicKey } from '@solana/web3.js'
import { useEffect } from 'react'
import NftMetadata from '../interfaces/NftMetadata'
import { DRAGO_FREEZE_PERIOD } from '../vars'
import Countdown from 'react-countdown'
import { checkDragoFreeze } from '../utils/checkDragoStatus'
import parseNftName from '../utils/parseNftName'
import { ActionCaller } from '../interfaces/Action'
import Img from './Img'

interface Props {
    walletDragos: NftMetadata[],
    dragosData: any[],
    addBuilder: ActionCaller
}

const WalletDragos = (props: Props) => {
    useEffect(() => {
        console.log({ dragosData: props.dragosData })
    }, [])

    return (
        <div className='walletDragos'>
            <p>After you have started a build, you need to send dragos to work on it. You can build faster by sending more dragos. Each worker after the first costs 100 $EMBER. Refer to the docs for the full formula. Remember that your drago is locked after you send it to a build.</p>
            {props.walletDragos.length > 0 ?
                <div className="nftList">
                    {props.walletDragos.map((drago, index) => {
                        const isFrozen = checkDragoFreeze(props.dragosData[index])
                        return (<div key={drago.mint.toBase58()} className="nft" >
                            <Img className="image" src={drago.image} />
                            <div className="name">
                                <h4 className='collection'>{parseNftName(drago.name).collection}</h4>
                                <h4 className='index'>{parseNftName(drago.name).index}</h4>
                            </div>
                            {isFrozen ?
                                <button disabled={true} className="actionButton"><Countdown date={(props.dragosData[index].lastBuild.toNumber() + DRAGO_FREEZE_PERIOD) * 1000} /></button>
                                : <button onClick={() => props.addBuilder([drago.mint])} disabled={isFrozen} className="actionButton sendButton">Send</button>}
                        </div>)
                    })}
                </div> : <h3>You don't have Dragos in your wallet</h3>}
        </div>
    )
}

export default WalletDragos