import * as anchor from "@project-serum/anchor";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { EMBER_MINT, FEE_COLLECTOR } from "../../vars";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: anchor.web3.PublicKey[]) => {
    const { wallet } = provider
    const [buildAccount, buildAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("build_account"), wallet.publicKey.toBuffer()], program.programId)
    const [statsAccount, statsAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("stats")], program.programId)

    const initializerEmberAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, EMBER_MINT, wallet.publicKey)
    const feeCollectorEmberAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, EMBER_MINT, FEE_COLLECTOR)

    return program.methods.startBuild().accounts({
        initializer: wallet.publicKey,
        buildAccount,
        stats: statsAccount,
        emberMint: EMBER_MINT,
        initializerEmberAta,
        feeCollector: FEE_COLLECTOR,
        feeCollectorEmberAta
    }).transaction()
}