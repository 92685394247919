import { PublicKey } from "@solana/web3.js"

export const NETWORK = 'mainnet-beta'
export const RPC_ENDPOINT = 'https://api.mainnet-beta.solana.com/'
export const API_ENDPOINT = 'https://metalairs-api.onrender.com'
export const DRAGOS_CREATORS = [
    '4g8EqmBkXz4uncG8CqhPfTbnyweVbVYcaLRE63xaB3fn',
    'C3g9SmSu8GfwfJKeKguga76Wq9sHj9M63Jbged95yEhR',
    '5dGSfWBSJHoVzmuoGVNxAKHDtojMT6MrWqWeX28csN1Y',
    'BS1F4vpTmHG55GBPfRcCk1sVYUQ3dRRLUvPySztMHvek',
    '81AKiT9nkL2ajnvy38JjnQyfdBxU29oNups4qFsDEEUN',
    'EjDgoVyup2eCx49KffoSbhqiCx2HXWYqhew415BaavfR', // Overlord
    'Gw2mS8kH8SjuX4KxQR7Kkxwau8RaRvGJuMmnY2LBfYo7', // GEN-1
    '3s5xGtHBGYvRk3LuG2heFithCkuJYin5UZagDpT1FZnQ' // test one
]
export const LAIRS_CREATORS = [
    '8jNeRsQmx7rRhZzNNNeMiaF3zhe6zCTAnecmvL3g26nV'
]
export const DRAGOS_PREFIXES = [
    'Xmas MetaDrago',
    'MetaDrago GEN-2',
    'MetaDrago Mutant',
    'MetaDrago #',
    'Cosmic',
    'Royal',
    'Unique'
]
export const DRAGO_GENS = {
    'Xmas MetaDrago': 2,
    'MetaDrago GEN-2': 2,
    'MetaDrago Mutant': 2,
    'Cosmic': 2,
    'Royal': 2,
    'Unique': 2,
    'Overlord': 2,
    'MetaDrago #': 1,
}

export const BUILD_DRAGOS_PREFIXES = [
    'MetaDrago GEN-2',
    'MetaDrago Mutant',
    'Xmas MetaDrago'
]
export const PROGRAM_ID = new PublicKey('ENjDCVut1D11jUDGymT2Jznw1FbhA3KsneztehWoUwKf')
export const EMBER_MINT = new PublicKey('Emberc567ToSP9FgAMuJWHXiQnEKVWrWC6gy64zbsK8u')
export const FEE_COLLECTOR = new PublicKey('DsN2iTnjhMCvoPptBhfiENvKNhK8Rj9ijifcWx9g82qS')
export const BASE_RATE = 1
export const BASE_POWER = 0.9
export const NEEDED_BUILD_POINTS = 86400 * 28
export const NEEDED_ACTION_POINTS = {
    1: 86400 * 14
}
export const DRAGO_FREEZE_PERIOD = 86400 * 28
export const LAIR_BUILD_EMBER_FEE = 2100
export const INCREASE_LAIR_COUNT = 100
export const INCREASE_LAIR_EMBER_FEE = 100