import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getMetadata from "../../utils/getMetadata";
import { EMBER_MINT } from "../../vars";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: PublicKey[]) => {
    const { wallet } = provider
    const [dragoMint] = mints
    const [dragoPlayAccount, dragoPlayAccountBump] = await anchor.web3.PublicKey.findProgramAddress([dragoMint.toBuffer(), wallet.publicKey.toBuffer()], program.programId)

    return program.methods.cancelAction().accounts({
        initializer: wallet.publicKey,
        dragoPlayAccount,
        dragoMint
    }).transaction()
}