import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import '../css/Navbar.css'

const Navbar = () => {
    return (
        <div className="navBarWrapper">
            {/* <div className='alert'>
                <h4>MetaLairs Project is temporarily stopped until Solana Wallet Exploit blows over. Keep Calm and use Ledger</h4>
            </div> */}
            <div className="navBar">
                <NavLink to='/' className='brand'>
                    <img className='logo' src='/img/logo.png' />
                    <h2 className='name'>MetaDrago<br />DAO</h2>
                </NavLink>
                <div className='navigation'>
                    <NavLink className={({ isActive }) => isActive ? 'link activeLink' : 'link'} to='/'>Home</NavLink>
                    <NavLink className={({ isActive }) => isActive ? 'link activeLink' : 'link'} to='/build'>Build</NavLink>
                    <NavLink className={({ isActive }) => isActive ? 'link activeLink' : 'link'} to='/play'>Play</NavLink>
                    <NavLink className={({ isActive }) => isActive ? 'link activeLink' : 'link'} to='/explorer'>Explorer</NavLink>
                    <a href='https://docs.metadrago.art/' className='link' target='_blank' rel='noopener noreferrer'>Docs</a>
                </div>
                <div className='wallet'>
                    <WalletMultiButton />
                </div>
            </div>
        </div>
    )
}

export default Navbar