import { useState } from 'react'
import * as anchor from "@project-serum/anchor";
import '../css/BuildStats.css'
import { INCREASE_LAIR_COUNT, INCREASE_LAIR_EMBER_FEE, LAIR_BUILD_EMBER_FEE, PROGRAM_ID } from '../vars';
import { useEffect } from 'react';
import { Metalairs } from '../idl';

interface BuildStatsProps {
    program: anchor.Program<Metalairs>
}

const BuildStats = (props: BuildStatsProps) => {
    const [stats, setStats] = useState<any>()

    const loadStatsAccount = async () => {
        const [statsAccount, statsAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("stats")], PROGRAM_ID)
        const statsAccountData = await props.program.account.statsAccount.fetch(statsAccount)
        setStats(statsAccountData)
    }

    const getBuildFee = (stats: any) => {
        const totalLairs = stats.lairsBuilt.toNumber() + stats.lairsBuilding.toNumber()
        return LAIR_BUILD_EMBER_FEE + INCREASE_LAIR_EMBER_FEE * (Math.trunc(totalLairs / INCREASE_LAIR_COUNT))
    }

    useEffect(() => {
        loadStatsAccount()
    }, [])

    return (
        <div className="buildStats">
            {stats ? <div className='stat' >
                <h2>Lairs Built: <span className='value'>{stats.lairsBuilt.toNumber()}</span></h2>
                <h2>Lairs Building: <span className='value'>{stats.lairsBuilding.toNumber()}</span></h2>
                <h2>Build Fee: <span className='value'>{getBuildFee(stats)} $EMBER + 0.1 $SOL</span></h2>
            </div > : null}
        </div >
    )
}

export default BuildStats