import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/Build.css'

import * as anchor from "@project-serum/anchor";
import { Connection, PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react"
import { IDL, Metalairs } from "../idl";
import NftMetadata from "../interfaces/NftMetadata";
import { BUILD_DRAGOS_PREFIXES, DRAGOS_CREATORS, DRAGO_FREEZE_PERIOD, PROGRAM_ID, RPC_ENDPOINT } from "../vars";
import getDragoAccountData from '../utils/getDragoAccountData';
import * as metaplex from '@metaplex/js'
import getExternalMetadata from '../utils/getExternalMetadata';
import { checkDragoFreeze, checkDragoInBuild } from '../utils/checkDragoStatus'
import Countdown from 'react-countdown'
import parseNftName from '../utils/parseNftName';
import checkNft from '../utils/checkNft';
import Img from '../components/Img';

const DragosExplorerViewer = () => {
    const { publicKey } = useParams()
    const [provider, setProvider] = useState<anchor.AnchorProvider>()
    const [isLoading, setIsLoading] = useState(true)
    const [dragoAccountData, setDragoAccountData] = useState<any>()
    const [dragoMetadata, setDragoMetadata] = useState<NftMetadata>()
    const wallet = useAnchorWallet()
    const connection = new Connection(RPC_ENDPOINT)

    const loadExternalMetadata = async (connection: Connection, dragoMint: PublicKey) => {
        const dragoMetadata = await metaplex.programs.metadata.Metadata.findByMint(connection, dragoMint)
        if (checkNft(dragoMetadata.data, BUILD_DRAGOS_PREFIXES, DRAGOS_CREATORS)) {
            const nftExternalMetadata = await getExternalMetadata(dragoMetadata.data)
            return nftExternalMetadata
        }
    }

    const loadDragoAccount = async (dragoMint: PublicKey, program: anchor.Program<Metalairs>) => {
        try {
            const [nftExternalMetadata, dragoAccountData] = await Promise.all([
                loadExternalMetadata(connection, dragoMint),
                getDragoAccountData(dragoMint, program)
            ])
            if (!nftExternalMetadata) return
            setDragoAccountData(dragoAccountData)
            setDragoMetadata(nftExternalMetadata)
        } catch (e) { }

        setIsLoading(false)
    }

    useEffect(() => {
        if (!publicKey || !wallet) return
        if (provider?.wallet.publicKey.equals(wallet.publicKey)) return

        const newProvider = new anchor.AnchorProvider(connection, wallet, { commitment: 'confirmed' })
        const program = new anchor.Program(IDL, PROGRAM_ID, newProvider)

        anchor.setProvider(newProvider)
        setProvider(newProvider)

        loadDragoAccount(new PublicKey(publicKey), program)
    }, [publicKey, wallet])

    return (
        <div className="Page ExplorerViewer">
            {wallet ?
                isLoading ?
                    <h3>Loading...</h3>
                    : dragoMetadata ?
                        <div className='exploreViewer'>
                            <div className='nft'>
                                <Img className="image" src={dragoMetadata.image} />
                                <div className="name">
                                    <h4 className='collection'>{parseNftName(dragoMetadata.name).collection}</h4>
                                    <h4 className='index'>{parseNftName(dragoMetadata.name).index}</h4>
                                </div>
                                <div className='data'>
                                    {
                                        dragoAccountData && checkDragoInBuild(dragoMetadata.mint, connection) ?
                                            <div className='statusInfo'>
                                                <h4 className='status'>In active build</h4>
                                            </div>
                                            : dragoAccountData && checkDragoFreeze(dragoAccountData) ?
                                                <div className='statusInfo'>
                                                    <h4 className='status'>Locked<br /><Countdown date={(dragoAccountData.lastBuild.toNumber() + DRAGO_FREEZE_PERIOD) * 1000} /></h4>
                                                </div>
                                                : <h4 className='status'>Not Frozen</h4>
                                    }
                                </div>
                            </div>
                        </div>
                        : <h3>Mint does not refer to a Gen-2 Drago</h3>
                : <h1 className='connectWalletAlert'>Connect your wallet to access this page</h1>}
        </div >
    )
}

export default DragosExplorerViewer