
import '../css/Build.css'
import '../css/Home.css'

import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const Home = () => {

    const navigate = useNavigate()

    return (
        <div className="Page Home">
            <div className="slide">
                <div className="infoWrapper">
                    <div className='info'>
                        <h1 className='title'>Welcome to the MetaLairs</h1>
                        <h3 className='subtitle'>Your ticket to the MetaDrago Metaverse</h3>
                        <h4 className='about'>The Drago Lairs will be a separate NFT collection from the main Meta Drago collection that will become the foundation for our chapter 2 roadmap, in which we will progressively build more advanced staking, play, earn, and risk protocols for the dragos alongside evolving lore in which you and your dragos will participate in.
                            In a nutshell, lairs will help you earn more GON, provide access to new staking opportunities for your dragos, find rare loot on adventures to haul back to your lair, and serve as your base of operations, in addition to practical utilities tied to other aspects of our ecosystem.</h4>
                        <h4 className='pointsTitle'>Important info:</h4>
                        <ul className='points'>
                            <li className="point">
                                <h4>Once you start a build, canceling it does not return the fees spent to start the build</h4>
                            </li>
                            <li className="point">
                                <h4>When a drago is sent to work on the build, it will be locked until the build is finished. If you want to unstake any workers, you will have to cancel the entire build. This will reset the progress to 0</h4>
                            </li>
                            <li className="point">
                                <h4>There is a cooldown on gen2 dragos after they participate in and complete a build to 100% to mint a lair. Every gen2 that participated (was locked) in the build process for that lair will need to wait 28 days before it can participate in another build for a new lair. Regardless of how many dragos you used to build the lair, all of them will enter cooldown when you mint the lair. You can check the cooldown status in the <Link className='hyperLink' to='/explorer'>explorer</Link></h4>
                            </li>
                            <li className="point">
                                <h4>Each wallet address is limited to one active lair build at a time, and every drago sent to build will work on that lair. If you want to mint multiple lairs, you have to start new builds in different wallets</h4>
                            </li>
                        </ul>
                        <div className='actionButtons'>
                            <button onClick={() => navigate(`build`)} className='actionButton'>Start Building</button>
                            <a href='https://docs.metadrago.art/' target='_blank' rel='noopener noreferrer'><button className='actionButton secondAction'>Explore Docs</button></a>
                        </div>
                    </div>
                </div>
                <div className='brandWrapper'>
                    <div className="brand">
                        <img className='lairExample' src='/img/lairExample.gif' />
                        {/* <h4 className='lairCaption'>MetaLair</h4> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Home