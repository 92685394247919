import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getMetadata from "../../utils/getMetadata";
import { EMBER_MINT } from "../../vars";
import startAction from "./startAction";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: PublicKey[]) => {
    return startAction(provider, program, mints, 1)
}