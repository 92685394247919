import { BASE_POWER, BASE_RATE } from "../vars"

export const earnPerSecond = (numBuilders: number) => {
    const earnPower = BASE_POWER ** (numBuilders - 1)
    const earnPerSecond = BASE_RATE * numBuilders * earnPower

    return earnPerSecond
}

export default (numBuilders: number, lastClaim: number) => {
    const unixTimestamp = Math.round(Date.now() / 1000)
    const earnedPoints = (unixTimestamp - lastClaim) * earnPerSecond(numBuilders)

    return earnedPoints
}