import * as anchor from "@project-serum/anchor"
import { useAnchorWallet } from "@solana/wallet-adapter-react"
import { useEffect, useState } from "react"
import { PublicKey } from "@solana/web3.js"
import InfoModal from "../components/InfoModal"
import { IDL, Metalairs } from "../idl"
import NftMetadata from "../interfaces/NftMetadata"
import PageProps from "../interfaces/PageProps"
import getExternalMetadata from "../utils/getExternalMetadata"
import getOwnerNfts from "../utils/getOwnerNfts"
import checkNft from "../utils/checkNft"
import { DRAGOS_CREATORS, DRAGOS_PREFIXES, LAIRS_CREATORS, PROGRAM_ID } from "../vars"
import getErrorText from "../utils/getErrorText"
import actions from "../actions"
import LockedLairs from "../components/LockedLairs"
import NftList from "../components/NftList"
import { Action } from "../interfaces/Action"
import sendAndConfirmTx from "../utils/sendAndConfirmTx"
import sortNft from "../utils/sortNft"

const Play = (props: PageProps) => {
    const [provider, setProvider] = useState<anchor.AnchorProvider>()
    const [program, setProgram] = useState<anchor.Program<Metalairs>>()
    const [walletDragos, setWalletDragos] = useState<Array<NftMetadata>>([])
    const [walletLairs, setWalletLairs] = useState<Array<NftMetadata>>([])
    const [lockedLairs, setLockedLairs] = useState<Array<NftMetadata>>([])
    const [loadingStates, setLoadingStates] = useState({
        walletLairs: false,
        lockedLairs: false
    })
    const [modalNotification, setModalNotification] = useState<{ status: string, text: string, action?: any }>({ status: '', text: '' })

    const wallet = useAnchorWallet()

    const loadNfts = async (provider: anchor.Provider, program: anchor.Program<Metalairs>) => {
        if (!wallet) return

        setLoadingStates(loadingStates => ({ ...loadingStates, walletDragos: true, walletLairs: true }))

        const nfts = await getOwnerNfts(provider.connection, wallet.publicKey)
        nfts.forEach(async nft => {
            if (!(checkNft(nft, DRAGOS_PREFIXES, DRAGOS_CREATORS) || checkNft(nft, ['MetaLair'], LAIRS_CREATORS))) {
                return
            }
            const nftExternalMetadata = await getExternalMetadata(nft)
            if (!nftExternalMetadata) return

            if (checkNft(nft, DRAGOS_PREFIXES, DRAGOS_CREATORS)) {
                setWalletDragos(nfts => [...nfts, nftExternalMetadata].sort(sortNft))
            } else if (checkNft(nft, ['MetaLair'], LAIRS_CREATORS)) {
                setWalletLairs(nfts => [...nfts, nftExternalMetadata].sort(sortNft))
            }
        })

        setLoadingStates(loadingStates => ({ ...loadingStates, walletDragos: false, walletLairs: false }))
    }

    const loadLockedLairs = async (provider: anchor.Provider, program: anchor.Program<Metalairs>) => {
        if (!wallet) return

        setLoadingStates(loadingStates => ({ ...loadingStates, lockedLairs: true }))

        const [playAccount, playAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("play_account"), wallet.publicKey.toBuffer()], program.programId)

        const nfts = await getOwnerNfts(provider.connection, playAccount)
        nfts.forEach(async nft => {
            if (!checkNft(nft, ['MetaLair'], LAIRS_CREATORS)) {
                return
            }
            const nftExternalMetadata = await getExternalMetadata(nft)
            if (!nftExternalMetadata) return

            setLockedLairs(nfts => [...nfts, nftExternalMetadata].sort(sortNft))
        })

        setLoadingStates(loadingStates => ({ ...loadingStates, lockedLairs: false }))
    }

    const reset = () => {
        setLockedLairs([])
        setWalletDragos([])
        setWalletLairs([])
    }

    useEffect(() => {
        if (!wallet) return
        if (provider?.wallet.publicKey.equals(wallet.publicKey)) return

        const newProvider = new anchor.AnchorProvider(props.connection, wallet, { commitment: 'confirmed' })
        const newProgram = new anchor.Program(IDL, PROGRAM_ID, newProvider)

        anchor.setProvider(newProvider)
        setProvider(newProvider)
        setProgram(newProgram)

        loadNfts(newProvider, newProgram)
        loadLockedLairs(newProvider, newProgram)
    }, [wallet])

    const callAction = async (action: Action, mints: PublicKey[]) => {
        if (!program || !wallet || !provider) return

        try {
            const tx = await action(provider, program, mints)
            tx.recentBlockhash = !tx.recentBlockhash ? (await provider.connection.getLatestBlockhash()).blockhash : tx.recentBlockhash
            tx.feePayer = !tx.feePayer ? provider.wallet.publicKey : tx.feePayer
            const signedTx = await provider.wallet.signTransaction(tx)
            setModalNotification({ status: 'wait', text: 'Confirming Transaction...' })
            await sendAndConfirmTx(provider.connection, signedTx, { skipPreflight: true })
            setModalNotification({ status: 'success', text: 'Transaction Completed' })
            reset()
            loadNfts(provider, program)
            loadLockedLairs(provider, program)
        } catch (e: any) {
            console.log(e)
            setModalNotification({ status: 'error', text: getErrorText(e) })
        }
    }

    return (
        <div className="Page">
            {wallet ?
                <>
                    <h2 className="sectionTitle"><span className='highlight'>W</span>allet Lairs:</h2>
                    <p>Each of the lairs in your wallet can accommodate a number of dragos once they are installed in-game (sent to the game's smart contract). Use the slots shown to the right of the lair to assign dragos to each lair. Once at least 1 drago is assigned, the lair will become active (start producing EMBER) and its assigned dragos will be able to take in-game actions, but while there are one or more dragos assigned to a lair, it cannot be uninstalled (withdrawn to wallet) until you unassign the dragos. Assigning a drago also sends it into the game's smart contract, but you can withdraw it any time while it's not committed to any specific action.</p>
                    {!loadingStates.walletLairs ? <NftList name={'Lairs'} nfts={walletLairs} additionalMints={[]} actions={[{ action: (mints: PublicKey[]) => callAction(actions.play.lockLair, mints), name: 'Install' }]} /> : <h3>Loading...</h3>}
                    <h2 className="sectionTitle"><span className='highlight'>L</span>ocked Lairs:</h2>
                    {program && provider ? <LockedLairs lockedLairs={lockedLairs} walletDragos={walletDragos} actions={[{ action: (mints: PublicKey[]) => callAction(actions.play.unlockLair, mints), name: 'Uninstall' }, { action: (mints: PublicKey[]) => callAction(actions.play.lockDrago, mints), name: 'Assign' }, { action: (mints: PublicKey[]) => callAction(actions.play.startWork, mints), name: 'Start Work' }, { action: (mints: PublicKey[]) => callAction(actions.play.unlockDrago, mints), name: 'Unassign' }, { action: (mints: PublicKey[]) => callAction(actions.play.cancelAction, mints), name: 'Cancel Work' }, { action: (mints: PublicKey[]) => callAction(actions.play.claimAction, mints), name: 'Claim Action' }]} isLoading={loadingStates.lockedLairs} config={{ provider, program, wallet }} /> : null}
                </> : <h1 className='connectWalletAlert'>Connect your wallet to access this page</h1>
            }
            {modalNotification.status ? <InfoModal modalNotification={modalNotification} setModalNotification={setModalNotification} /> : null}
        </div >
    )
}

export default Play