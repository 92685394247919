import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metalairs } from "../../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getMetadata from "../../utils/getMetadata";
import { EMBER_MINT } from "../../vars";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Metalairs>, mints: PublicKey[]) => {
    const { wallet } = provider
    const [dragoMint] = mints
    const [dragoPlayAccount, dragoPlayAccountBump] = await anchor.web3.PublicKey.findProgramAddress([dragoMint.toBuffer(), wallet.publicKey.toBuffer()], program.programId)
    const [vaultAccount, vaultAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("vault")], program.programId)

    const dragoMetadata = await getMetadata(dragoMint)

    const initializerEmberAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, EMBER_MINT, wallet.publicKey)
    const vaultEmberAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, EMBER_MINT, vaultAccount, true)

    console.log({ vaultAccount: vaultAccount.toBase58() })
    return program.methods.claimAction(vaultAccountBump).accounts({
        initializer: wallet.publicKey,
        dragoPlayAccount,
        dragoMetadata,
        dragoMint,
        emberMint: EMBER_MINT,
        initializerEmberAta,
        vaultEmberAta,
        vault: vaultAccount,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY
    }).transaction()
}